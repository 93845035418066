import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Zoom from 'react-medium-image-zoom';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { Color } from '../../../assets/constants';
import {
  getImageAltText,
  TShirtSliderImage
} from '../../../containers/Details/utils';
import { TShirtColor } from '../../../containers/adminPanel/utils';
import { icons } from '../../../assets/icons';

interface Props {
  images: TShirtSliderImage[];
  selectedColor: TShirtColor | null;
  onSelectColor: (color: TShirtColor, isMobile?: boolean) => void;
}

export const TShirtSwiper = ({
  images,
  selectedColor,
  onSelectColor
}: Props) => {
  const swiperRef = useRef<SwiperClass>();
  const [imageLoadStates, setImageLoadStates] = useState<boolean[]>(
    images.map(() => false)
  );

  const handleImageLoad = (index: number) => {
    setImageLoadStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };

  const handleSlideChange = ({ activeIndex }: SwiperClass) => {
    if (images) {
      onSelectColor(images[activeIndex].color, true);
    }
  };

  useEffect(() => {
    const index = images.findIndex((image) => image.color === selectedColor);
    swiperRef.current?.slideTo(index);
  }, [images, selectedColor]);

  return (
    <Swiper
      onSwiper={(swiper: SwiperClass) => {
        swiperRef.current = swiper;
      }}
      onSlideChange={handleSlideChange}
    >
      {Object.values(images).map((image, index) => (
        <StyledSlide isSingle={images.length === 1} key={index}>
          {!imageLoadStates[index] && (
            <ActivityIndicator size={100} color={Color.ACCENT} />
          )}
          <Zoom
            swipeToUnzoomThreshold={200}
            zoomMargin={-200}
            IconUnzoom={() => <icons.FaTimes size={22} />}
          >
            <Image
              src={image.url}
              alt={getImageAltText(image.name)}
              onLoad={() => handleImageLoad(index)}
              loaded={imageLoadStates[index]}
            />
          </Zoom>
        </StyledSlide>
      ))}
    </Swiper>
  );
};

const Image = styled.img<{ loaded: boolean }>`
  max-width: 100%;
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const StyledSlide = styled(SwiperSlide)<{ isSingle: boolean }>`
  display: flex;
  justify-content: center;
  height: 390px;

  ${({ isSingle }) =>
    isSingle &&
    `
      width: 100% !important;
      height: auto !important;
  `}
`;
